import { flow, map, flatten } from 'lodash/fp';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';

import { formatDate } from 'utils/dateUtils';
import { formatNumber } from 'utils/formatAmount';
import { useFetchApiGet } from 'common/reduxutils';
import DataTable from 'common/ui/DataTable';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import Printable from 'components/Printable';
import businessApiCall from 'apiCalls/business';
import useTenant from 'components/use-tenant';
import variantApiCall from 'apiCalls/productVariant';

import { variantColumnsGenerator } from './config';

const HeaderComponent = ({
  currentPrintPage,
  totalPrintPage,
  companyInfo,
  statementDate,
}) => {
  return (
    <div>
      <div>
        <p>
          <strong>
            {companyInfo.company_name}
            {companyInfo.registration_number && (
              <> ({companyInfo.registration_number})</>
            )}
          </strong>
        </p>
        <p>{companyInfo.address1}</p>
        <p>{companyInfo.address2}</p>
        <p>{companyInfo.address3}</p>
        {companyInfo.phone && <p>Tel: {companyInfo.phone}</p>}
      </div>

      <div className="flex justify-center items-center">
        <p>
          <strong>Stock Tracking Statement</strong>
        </p>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <div className="grid grid-cols-2 gap-2">
            <div>
              <p>Statement Date:</p>
              <p>Page:</p>
            </div>
            <div>
              <p>{statementDate}</p>
              <p>
                {currentPrintPage} of {totalPrintPage}
              </p>
            </div>
          </div>
        </div>
        <div />
        <div className="col-span-5"></div>
      </div>
    </div>
  );
};

const DataTableComponent = ({ items, paging }) => {
  const { business_id } = useTenant();
  return (
    <DataTable
      rowKey="id"
      columns={variantColumnsGenerator({ business_id }).filter(
        ({ key }) => key != 'action'
      )}
      dataSource={items}
      totalItems={paging?.total_items}
      currentPage={paging?.current_page || paging?.page}
      defaultCurrent={1}
      defaultPageSize={paging?.page_size || paging?.per_page}
      pageSize={paging?.page_size || paging?.per_page}
      pagingEnabled={!!paging}
      size="small"
    />
  );
};

const SummaryComponent = ({ total }) => {
  return (
    <div className="flex flex-col flex-1">
      <div>
        <hr />
        <div className="flex items-start">
          <div className="mr-2"></div>
          <div className="flex-1"></div>
          <div />
          <div className="ml-2 mr-4">
            <p className="text-right">Total {formatNumber(total)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const useAllItemsQuery = payload => {
  const {
    data: { pages } = { pages: [] },
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [variantApiCall.list.queryKey, payload, 'print'],
    queryFn: context =>
      variantApiCall.list.queryFn({
        ...payload,
        page: context.pageParam,
        per_page: 500,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, _lastPageParams, _allPageParams) => {
      if (lastPage.paging.page < lastPage.paging.page_count) {
        return lastPage.paging.page + 1;
      }
    },
  });

  React.useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [pages.length]);

  const allItems = React.useMemo(
    () =>
      flow(
        map(page => page.items),
        flatten
      )(pages).toReversed(),
    [pages.length]
  );

  return [allItems, hasNextPage];
};

const StatementItemDetail = () => {
  const { business_id } = useTenant();
  const {
    data: companyInfo,
    load: fetchCompanyInfo,
    isLoading: isLoadingCompanyInfo,
  } = useFetchApiGet(businessApiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchCompanyInfo({ id: business_id });
  }, [business_id]);

  const urlParams = useParams();
  const params = { status: 1002 };
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const payload = { ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading: isLoadingData } = useQuery({
    queryKey: [variantApiCall.list.queryKey, payload],
    queryFn: () => variantApiCall.list.queryFn(payload),
  });
  const { items: baseItems = [], paging } = data;
  const isLoading = isLoadingCompanyInfo || isLoadingData;

  const total = paging?.total_items;
  const items = baseItems.toReversed();
  const [printItems, stillFetchingPrintItems] = useAllItemsQuery(payload);
  const statementDate = formatDate(new Date());

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Printable
      title="Statement"
      HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
        <HeaderComponent
          currentPrintPage={currentPrintPage}
          totalPrintPage={totalPrintPage}
          companyInfo={companyInfo}
          statementDate={statementDate}
        />
      )}
      DataTableComponent={DataTableComponent}
      SummaryComponent={() => <SummaryComponent total={total} />}
      items={items}
      printItems={printItems}
      paging={paging}
      readyToPrint={stillFetchingPrintItems === false}
    />
  );
};

export const StatementPage = () => {
  return (
    <>
      <PageTitle title="View details" />
      <StatementItemDetail />
    </>
  );
};
