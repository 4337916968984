import { Button } from 'antd';
import { Link, useSearchParams } from 'react-router-dom';
import React from 'react';

import { ListPage } from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import variantApiCall from 'apiCalls/productVariant';

import { getTenantUrl } from '../../utils/urlHelpers';
import { variantColumnsGenerator } from './config';

export const VariantStockListPage = () => {
  const { business_id } = useTenant();
  const [baseSearchParams] = useSearchParams();

  return (
    <ListPage
      title="Stock Tracking"
      namespace="stock-tracking"
      searchOptions={{ tooltip: false }}
      showBreadscrumb={true}
      apiCall={variantApiCall}
      columnGenerator={() => variantColumnsGenerator({ business_id })}
      resourceName="items"
      extraBtns={
        <Button type="primary">
          <Link
            to={getTenantUrl(
              business_id,
              `/stock-tracking/statement?${baseSearchParams.toString()}`
            )}
          >
            Statement
          </Link>
        </Button>
      }
    />
  );
};

export { InventoryStatementPage } from './InventoryStatementPage';
export { StatementPrintPage as InventoryStatementPrintPage } from './InventoryStatementPrintPage';
export { StatementPage as VariantStockStatementPage } from './statement';
